import { Link } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import api_config from "../../../../api/api_config";
import axios from "axios";
import "./style.css";
import Navs from "../navs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchReportSummary,
  fetchReportPlays,
  fetchReportImpressions,
} from "../../../../api/reports";
import { fetchFollowersApi } from "../../../../api/publisher";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

function PodcasterHome() {
  const location = useLocation();
  const navigate = useNavigate();

  const user = JSON.parse( localStorage.getItem('RIFALYUSER'));
  const canUpload = parseInt(user.role) === 2 || parseInt(user.role) === 6;
  const [isLoading, setIsLoading] = useState(false);
  const [retry, setRetry] = useState(false);
  const [summaryData, setSummaryData] = useState();
  const [platform, setPlatform] = useState();
  const [pieTitle, setPieTitle] = useState("Plays by Platform");
  const [pieData, setPieData] = useState([]);
  const [barTitle, setBarTitle] = useState("Plays by Location");
  const [barData, setBarData] = useState([]);
  const [playData, setPlayData] = useState([]);
  const [totalPlaysData, setTotalPlaysDataData] = useState([]);
  const [timeFilter, setTimeFilter] = useState("monthly");
  const [impressionsData, setImpressionsData] = useState([]);
  const [followers, setFollowers] = useState(0);
  const thousandSeparator = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "n/a";
  };
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const YEARS = [
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
  ];

  const refresh = async () => {
    setIsLoading(true);
    // try {
    //   const result = await fetchReportSummary({ id: pid });
    //   setSummaryData(result);
    //   localStorage.setItem('RIFALYSUMMARY', JSON.stringify(result));
    //   setIsLoading(false);
    //   setRetry(false);
    // } catch (e) {
    //   setIsLoading(false);
    //   setRetry(true);
    // }
  };

  const handleTimeFilterChange = async (e) => {
    const selectedFilter = e.target.value;
    setTimeFilter(selectedFilter);

    fetchReportPlays(selectedFilter);
    fetchReportImpressions(selectedFilter);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          summaryResponse,
          totalplaysResponse,
          playsResponsePie,
          playsResponseBar,
          playsResponseLine,
          impressionsResponse,
          followersResponse,
        ] = await Promise.all([
          fetchReportSummary({
            token: { Authorization: `Bearer ${user.token}` },
          }),
          fetchReportPlays({
            token: { Authorization: `Bearer ${user.token}` },
          }),
          fetchReportPlays({
            reportType: "platform",
            token: { Authorization: `Bearer ${user.token}` },
          }),
          fetchReportPlays({
            reportType: "country",
            token: { Authorization: `Bearer ${user.token}` },
          }),
          fetchReportPlays({
            reportType: timeFilter,
            token: { Authorization: `Bearer ${user.token}` },
          }),
          fetchReportImpressions({
            reportType: timeFilter,
            token: { Authorization: `Bearer ${user.token}` },
          }),
          // fetchFollowersApi(pid),
        ]);

        const pieData = playsResponsePie.data.reduce(
          (acc, item) => {
            acc.labels.push(item.os !== null ? item.os : "Others");
            acc.data.push(item.playCount);
            acc.backgroundColor.push(getRandomColor());
            return acc;
          },
          { labels: [], data: [], backgroundColor: [] }
        );

        setSummaryData(summaryResponse.data);
        setTotalPlaysDataData(totalplaysResponse.data);
        setPieData(pieData);
        setBarData(playsResponseBar.data);
        setPlayData(playsResponseLine.data);
        setImpressionsData(impressionsResponse.data);
        console.log("🚀 ~ fetchData ~ impressionsResponse:", impressionsResponse);
        setFollowers(followersResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    
    };

    fetchData();
  }, []);

  const getLabels = () => {
    switch (timeFilter) {
      case "yearly":
        return YEARS;
      case "monthly":
        return MONTHS;
      case "daily":
        // This assumes you have daily data available
        return playData?.map((item) => item.date.split("T")[0]) || [];
      default:
        return [];
    }
  };

  const TimeFilterSelect = ({ onChange }) => {
    return (
      <div className="col-auto align-self-end">
        <select onChange={onChange} defaultValue={timeFilter}>
          <option value="yearly">Yearly</option>
          <option value="monthly">Monthly</option>
          <option value="daily">Daily</option>
        </select>
      </div>
    );
  };
  // Line Chart Data
  const lineData = {
    responsive: true,
    labels: getLabels(),
    datasets: [
      {
        label: "Plays",
        data: MONTHS.map((month, index) => {
          let play = playData.find((item) => Number(item.date) - 1 === index);
          let count = play ? play.playCount : 0;
          return count;
        }),
        fill: false,
        borderColor: "#2BC38C",
        backgroundColor: "#2BC38C",
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        offset: false,
      },
      {
        label: "Impressions",
        data: MONTHS.map((month, index) => {
          let impression = impressionsData.find(
            (item) => Number(item.date) - 1 === index
          );
          let count = impression ? impression.impressionCount : 0;
          return count;
        }),
        fill: false,
        borderColor: "#000000",
        backgroundColor: "#000000",
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false,
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          font: {
            size: 13,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          display: false,
          borderColor: "transparent",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of users",
        },
        grid: {
          offset: false,
          borderDash: [1, 3],
          borderColor: "transparent",
          tickColor: "transparent",
          categoryPercentage: 0.4,
        },
      },
    },
  };

  // Function to generate random colors
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Doughnut Chart Data
  const doughnutData = {
    labels: pieData.labels,
    datasets: [
      {
        data: pieData.data,
        backgroundColor: pieData.backgroundColor,
      },
    ],
    responsive: true,
    maintainAspectRatio: true,
    radius: 120,
    weight: 1,
    borderWidth: 1,
    hoverOffset: 7,
  };

  //css for the doughnut
  const pieChart = {
    cutout: 90,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#BCBCBC",
          font: {
            size: 14,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
    },
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <div className="row">
                <div className="col-12 col-lg-10 col-xl-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/dashboard" onClick={toDashboard}>
                            Dashboard
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Podcasters
                        </li>
                      </ol>
                    </nav>

                    <div className="header-body">
                      <h6 className="header-pretitle">Overview</h6>

                      <h1 className="header-title">Podcasters</h1>
                    </div>
                  </div>

                  <div className="content">
                    <Navs></Navs>
                  </div>
                </div>

                <div className="col-auto align-self-end">
                  {/* <TimeFilterSelect onChange={handleTimeFilterChange} /> */}
                </div>

                <div className="col-auto">
                  {canUpload ? (
                    <Link
                      to="/publications/stories/create"
                      className="btn btn-primary lift mt-4"
                    >
                      <span>
                        <i className="fe fe-plus"></i>
                      </span>
                      Upload Story
                    </Link>
                  ) : null}
                </div>
              </div>

              <hr></hr>
            </div>

            <div className="content">
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Select Start Date"
                        defaultValue={start}
                        onChange={handleDateChange}
                        id="start"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Select End Date"
                        defaultValue={start}
                        onChange={handleDateChange}
                        id="end"
                      />
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        <span className="fe fe-search"></span>&nbsp;Search
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              {isLoading ? (
                <div className="row">
                  <div className="col-md-12 my-4 offset-4 my-4">
                    <Loader
                      type="TailSpin"
                      color="#46E7AD"
                      height={100}
                      width={100}
                    />
                  </div>
                </div>
              ) : retry ? (
                <div className="row">
                  <div className="col-md-4 my-4 offset-4 my-4 text-primary text-center ">
                    <span style={{ cursor: "pointer" }} onClick={refresh}>
                      <i className="material-icons text-primary">cached</i>
                    </span>
                    <p>Reload</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 my-4">
                    <div className="row">
                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h6 className="text-capitalize text-muted mb-3">
                                  Sales
                                </h6>
                                <span className="h2 mb-0 mr-4">
                                  TZS{" "}
                                  {summaryData && summaryData.revenue
                                    ? thousandSeparator(
                                        Number(summaryData.revenue).toFixed(2)
                                      )
                                    : 0}
                                </span>

                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h6 className="text-capitalize text-muted mb-3">
                                  Impressions
                                </h6>
                                <span className="h2 mb-0">
                                  {summaryData && summaryData.impressions
                                    ? thousandSeparator(summaryData.impressions)
                                    : 0}
                                </span>
                                <span className="badge bg-success-soft mr-2 mt-n1">
                                  {platform}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h5 className="text-capitalize text-muted mb-3">
                                  Streams
                                </h5>

                                <span className="h2 mb-0">
                                  {summaryData && summaryData.streamsTotal
                                    ? thousandSeparator(summaryData.streamsTotal)
                                    : 0}
                                </span>

                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h5 className="text-capitalize text-muted mb-3">
                                  Followers
                                </h5>

                                <span className="h2 mb-0">{followers}</span>

                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-8">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">
                              Channel Performance
                            </h4>
                          </div>
                          <div className="card-body">
                            <Line data={lineData} options={optionsLine} />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-xl-4">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{pieTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <Doughnut data={doughnutData} options={pieChart} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-4">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{barTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <div className="bars">
                              {barData.map((item) => (
                                <>
                                  <div className="labels">
                                    <div key={item.country} className="label">
                                      {item.country}
                                    </div>
                                  </div>
                                  <div
                                    key={item.country}
                                    className="bar-container"
                                  >
                                    <div
                                      className="bar"
                                      style={{
                                        width: `${(
                                          (item.playCount / totalPlaysData) *
                                          100
                                        ).toFixed(2)}%`,
                                        backgroundColor: "#2BC38C",
                                      }}
                                    ></div>
                                    <span className="percentage-label">
                                      {(
                                        (item.playCount / totalPlaysData) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
export default PodcasterHome;
