import React, { useMemo, useEffect } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import { useDispatch, useSelector } from "react-redux";
import { PUBLICATIONCOLUMNS } from "./PublicationColumns";
import { fetchPublications } from "../../../../redux-store/Actions/publication-action";
import PublicationTable from "./PublicationTable";
import { useState } from "react";
import { fetchPublicationsApi } from "../../../../api/publisher";

export default function Publications() {
  const columns = useMemo(() => PUBLICATIONCOLUMNS, []);
  // const dispatch = useDispatch();

  // const { data } = useSelector((state) => state);
  const [raw, setRaw] = useState({});
  const [publications, setPublications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  const handlePageChange = async (page) => {
    if (page && page < 1) page = 1;
    const raw = await fetchPublicationsApi(page);
    setRaw(raw.data.data);
    setPublications(raw.data.data.publications || []);
  };

  useEffect(() => {
    // dispatch(fetchPublications());
    // setRaw(data);
    // setPublications(data?.publications || []);
    handlePageChange(1);
  }, []);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Publishers
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Publishers</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>

              <PublicationTable
                name="Publications"
                title="Registered Publications"
                columns={columns}
                data={publications}
                raw={raw}
                path="/publishers"
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
