import React from "react";
import DashboardWrapper from "../../../components/DashboardWrapper";
import DashboardNav from "./DashboardNav";
import RevenueSummary from "./RevenueSummary";

function Revenue() {
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>
                <h1 className="header-title">Dashboard</h1>
              </div>
            </div>

            <div className="content">
              <DashboardNav></DashboardNav>

              <RevenueSummary />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Revenue;
