import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchAllChannels } from "../../../../redux-store/Actions/channel-action";
import { useDispatch, useSelector } from "react-redux";
import {
  createStoriesApi,
  createStoriesViaRSSApi,
  fetchSeasonsDetailsApi,
  createStoriesSeasonsApi,
} from "../../../../api/publisher";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchStories } from "../../../../redux-store/Actions/story-action";
import { fetchGenres } from "../../../../redux-store/Actions/genre-action";
import { ReactComponent as ArrowIcon } from "../../../../assets/img/btn_arrow.svg";

const schema = yup
  .object({
    title: yup
      .string()
      .required()
      .min(3)
      .max(255)
      .label("Please enter a title"),
    channelId: yup.string().required("Please select a channel"),
  })
  .required();

export default function CreateStory() {
  const dispatch = useDispatch();
  const { allChannels, isLoading } = useSelector((state) => state.channel);
  const allPodcasterchannel = allChannels.data;
  const activeChannels = Array.isArray(allPodcasterchannel)
    ? allPodcasterchannel.filter((channel) => channel?.status === "ACTIVE")
    : [];
  const data = activeChannels;

  const { genres } = useSelector((state) => state.genres);
  const {
    register,
    handleSubmit,
    handleInputChange,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const [loadedFile, setLoadedFile] = useState([]);
  const [publicationFile, setPublicationFile] = useState({});
  const [publishDate, setPublishDate] = useState(new Date());
  const [seasonCover, setSeasonCover] = useState(null);
  const [existingSeasons, setExistingSeasons] = useState([]);
  const [nextEpisodeNumber, setNextEpisodeNumber] = useState(1);
  const [contentStatus, setContentStatus] = useState("Existing");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState(false);
  const [fileType, setFileType] = useState("");
  const [showAudioForm, setShowAudioForm] = useState(false);
  const [urlInputDisabled, setUrlInputDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const countries = localStorage.getItem("RIFALYCOUNTRIES")
  ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
  : [];
  const watchChannelId = watch("channelId");

  useEffect(() => {
    if (watchChannelId) {
      setSelectedCompanyId(watchChannelId);
      getSeasonDetails(watchChannelId);
    }
  }, [watchChannelId]);

  const handleContentStatusChange = (value) => {
    setContentStatus(value);
    if (value === "New") {
      setNextEpisodeNumber(1);
      setSelectedSeason(null);
    }
  };

  const getSeasonDetails = (channelId) => {
    if (!channelId) return;
  
    fetchSeasonsDetailsApi(channelId)
      .then((response) => {
        if (response?.data?.data) {
          const newSeasons = response.data.data;
          setExistingSeasons(newSeasons);
          
          if (contentStatus === "New") {
            setSelectedSeason(null);
            setNextEpisodeNumber(1);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching season details:", error);
        toast.error("Failed to fetch season details");
        setExistingSeasons([]);
      });
  };

  const getMaxSeasonId = () => {
    if (existingSeasons.length === 0) return 1;
    const maxId = Math.max(...existingSeasons.map(season => season.id));
    return maxId + 1;
  };

  const handleSubmitRssFeed = (event) => {
    event.preventDefault();
    const urlInput = document.getElementById("RSSfeedurl")?.value?.trim();
    
    if (!urlInput || !validateUrl(urlInput)) {
      setError(true);
      return;
    }

    if (!selectedCompanyId) {
      toast.error("Please select a channel first");
      return;
    }

    setLoading(true);
    const channel = {
      channelId: selectedCompanyId,
      rssUrl: urlInput,
    };

    createStoriesViaRSSApi(channel)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Story Created successfully");
          dispatch(fetchStories());
          navigate("/podcasters/stories/");
        } else {
          toast.error("Failed to create story");
        }
      })
      .catch((error) => {
        console.error("Error creating story via RSS:", error);
        toast.error("An error occurred while creating the story");
      })
      .finally(() => {
        setLoading(false);
        setError(false);
        if (document.getElementById("RSSfeedurl")) {
          document.getElementById("RSSfeedurl").value = "";
        }
      });
  };

  const validateUrl = (url) => {
    const pattern = /^https?:\/\/.+$/;
    return pattern.test(url);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleTextInputClick = (event) => {
    event.stopPropagation();
  };

  const onDropSeasonCover = useCallback((acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const reader = new FileReader();
    
    reader.onload = () => {
      setSeasonCover({
        file: file,
        url: reader.result,
      });
    };
    
    reader.readAsDataURL(file);
  }, []);

  const onDropPublicationFile = useCallback((uploadedFiles) => {
    if (!uploadedFiles || uploadedFiles.length === 0) return;

    const file = uploadedFiles[0];
    setFileType(file.type);
    setLoadedFile(uploadedFiles);

    const reader = new FileReader();
    reader.onload = () => {
      setPublicationFile({
        url: reader.result,
      });
    };
    reader.readAsDataURL(file);

    if (file.type.startsWith("audio/")) {
      setShowAudioForm(true);
      setUrlInputDisabled(true);
      setShowForm(false);
    } else {
      setShowAudioForm(false);
      setUrlInputDisabled(false);
      setShowForm(false);
    }
    
    setError(false);
    if (document.getElementById("RSSfeedurl")) {
      document.getElementById("RSSfeedurl").value = "";
    }
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRssClick = (event) => {
    event.stopPropagation();
    setShowForm(true);
    setShowAudioForm(false);
  };

  const handleChosen = (event) => {
    const selectedId = event.target.value;
    setSelectedCompanyId(selectedId);
    setValue("channelId", selectedId);
    getSeasonDetails(selectedId);
  };

  const reset = () => {
    navigate("/podcasters/stories");
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    if (Object.keys(publicationFile).length === 0) {
      setLoading(false);
      toast.error("Error: Missing Story file, Try again");
      return;
    }

    if (!data.channelId) {
      setLoading(false);
      toast.error("Please select a channel");
      return;
    }

    const formData = {
      ...data,
      audioFile: publicationFile,
      fileInfo: loadedFile[0] ? {
        lastModified: loadedFile[0].lastModified,
        lastModifiedDate: loadedFile[0].lastModifiedDate,
        name: loadedFile[0].name,
        size: loadedFile[0].size,
        type: loadedFile[0].type,
      } : null,
      releaseDate: publishDate,
      seasonId: selectedSeason,
      episodeNumber: nextEpisodeNumber,
    };

    if (selectedOption === "season" && contentStatus === "New") {
      formData.seasonNumber = getMaxSeasonId();
    }

    createStoriesApi(formData)
      .then((res) => {
        toast.success("Story Created successfully");
        dispatch(fetchStories());
        navigate("/podcasters/stories");
      })
      .catch((error) => {
        console.error("Error creating story:", error);
        toast.error("Failed to create story");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toDashboard = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  };

  const toPage = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };

  useEffect(() => {
    dispatch(fetchGenres());
    dispatch(fetchAllChannels(user.token));
  }, [dispatch]);

  const renderAdditionalForm = () => {
    if (selectedOption === "season") {
      return (
        <div className="row g-3 mb-3">
          <div className="col-12 col-md-4 mb-1">
            <label htmlFor="contentstatus" className="form-label">
              Existing/New
            </label>
            <select
              className="form-select"
              defaultValue="Existing"
              onChange={(e) => handleContentStatusChange(e.target.value)}
            >
              <option value="Existing">Existing</option>
              <option value="New">New</option>
            </select>
          </div>

          {contentStatus === "Existing" ? (
            <div className="col-12 col-md-4 mb-1">
              <label htmlFor="Season" className="form-label">
                Season
              </label>
              <select
                className="form-select"
                {...register("season")}
                value={selectedSeason || ""}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  setSelectedSeason(selectedId);
                  const episode = existingSeasons.find(
                    (s) => parseInt(s.id) === parseInt(selectedId)
                  );
                  setNextEpisodeNumber(episode ? episode.numberOfEpisodes + 1 : 1);
                }}
              >
                <option value="" disabled>Select season number</option>
                {existingSeasons.map((season) => (
                  <option key={season.id} value={season.id}>
                    {season.title}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="col-12 col-md-4 mb-1">
              <label htmlFor="Season" className="form-label">
                Season
              </label>
              <select
                className="form-select"
                {...register("season")}
                value={getMaxSeasonId()}
                disabled
              >
                <option value={getMaxSeasonId()}>
                  {getMaxSeasonId()}
                </option>
              </select>
            </div>
          )}

          <div className="col-12 col-md-4 mb-1">
            <label htmlFor="Episode" className="form-label">
              Episode
            </label>
            <input
              type="text"
              className="form-control"
              value={nextEpisodeNumber}
              disabled
              {...register("episode")}
            />
          </div>

          <div className="col-12 col-md-12 mb-1">
            <label htmlFor="price" className="form-label">
              Description
            </label>
            <textarea
              type="number"
              {...register("description")}
              className="form-control"
              placeholder="Description"
            ></textarea>
          </div>

          <div className="col-md-4">
            <Dropzone
              onDrop={onDropSeasonCover}
              multiple={false}
              accept="image/*"
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Cover Art</label>
                  </div>
                  <div
                    {...getRootProps({
                      className: "dropzone dropzone-single mb-3",
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload</p>
                      <div className="d-flex justify-content-between RssFeedDrop">
                        <div
                          className="btn btn-sm btn-outline-primary"
                          style={{ borderRadius: "30px", minWidth: "270px" }}
                        >
                          Browse from your files
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-md-6">
            {seasonCover && seasonCover.url && (
              <aside className="file-preview">
                <div>
                  <img
                    className="viewer-mini"
                    src={seasonCover.url}
                    alt={seasonCover.file.name}
                    style={{ transform: "scale(0.80)" }}
                  />
                </div>
              </aside>
            )}
          </div>
        </div>
      );
    } else if (selectedOption === "episodes") {
      return (
        <div>
          <div className="col-12 col-md-12 mb-1">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              {...register("description")}
              className="form-control"
              placeholder="Description"
            ></textarea>
          </div>
          <div className="col-md-4">
            <Dropzone
              onDrop={onDropSeasonCover}
              multiple={false}
              accept="image/*"
              >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Cover Art</label>
                  </div>
                  <div
                    {...getRootProps({
                      className: "dropzone dropzone-single mb-3",
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload</p>
                      <div className="d-flex justify-content-between RssFeedDrop">
                        <div
                          className="btn btn-sm btn-outline-primary"
                          style={{ borderRadius: "30px", minWidth: "270px" }}
                        >
                          Browse from your files
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-md-6">
            {seasonCover && seasonCover.url && (
              <aside className="file-preview">
                <div>
                  <img
                    className="viewer-mini"
                    src={seasonCover.url}
                    alt={seasonCover.file.name}
                    style={{ transform: "scale(0.80)" }}
                  />
                </div>
              </aside>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const files = loadedFile.map((f) => (
    <li className="file-preview-item" key={f.name}>
      <div>
        <audio src={publicationFile.url} controls>
          {f.name}
        </audio>
      </div>
    </li>
  ));

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/podcasters" onClick={toPage("/podcasters/")}>
                      Podcasters
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/podcasters/stories" onClick={toPage("/podcasters/stories")}>
                      Stories
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload Story
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card pt-3">
                  <div className="px-4 pt-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="header-pretitle">Overview</h6>
                        <h1 className="header-title">Upload Story</h1>
                      </div>
                      <div className="col-auto">
                        <button className="btn btn-outline-primary btn-sm">
                          Save as Draft
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>

                  <div className="card-body" id="dropzone">
                    <div className="row">
                      <div className="col-md-12">
                        <Dropzone
                          onDrop={onDropPublicationFile}
                          multiple={false}
                          accept="audio/*"
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div className="mb-1">
                                <label className="form-label">Upload</label>
                              </div>
                              <div
                                {...getRootProps({
                                  className: "dropzone dropzone-single mb-3",
                                })}
                              >
                                <input {...getInputProps()} />
                                <div className="upload-content p-4">
                                  <i className="fe fe-upload-cloud"></i>
                                  <p>Drag and drop or click to upload</p>
                                  <div className="d-flex justify-content-between RssFeedDrop">
                                    <div
                                      className="btn btn-sm btn-primary"
                                      style={{
                                        borderRadius: "5px",
                                        minWidth: "270px",
                                      }}
                                    >
                                      Browse from your files
                                    </div>
                                    <div className="RSSor"> OR </div>
                                    <div
                                      className="btn btn-sm btn-outline-primary"
                                      style={{
                                        borderRadius: "30px",
                                        minWidth: "270px",
                                      }}
                                      onClick={handleRssClick}
                                    >
                                      Rss URL Upload
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>

                  {showForm && (
                    <form onSubmit={handleSubmitRssFeed} className="p-3" id="rssaudioForm">
                      <div className="row g-3 mb-3">
                        <div className="col-12 col-md-4 mb-1">
                          <label htmlFor="audio" className="form-label">
                            Channel
                          </label>
                          <select
                            className="form-select"
                            onChange={handleChosen}
                          >
                            <option value="" disabled selected>
                              Select Channel
                            </option>
                            {data.map((channel) => (
                              <option key={channel.id} value={channel.id}>
                                {channel.channelName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 col-md-6 mb-1">
                          <label htmlFor="urlinput" className="form-label">
                            URL
                          </label>
                          <input
                            type="url"
                            name="websiteUrl"
                            pattern="https?://.+"
                            placeholder="Enter your URL..."
                            className="form-control RSSfeedurl"
                            id="RSSfeedurl"
                            disabled={urlInputDisabled}
                            {...register("author")}
                          />
                        </div>
                        <div className="col-12 col-md-2 mb-1">
                          <button
                            type="submit"
                            className="urlinput_BTN"
                            disabled={!isCheckboxChecked}
                          >
                            <ArrowIcon />
                          </button>
                        </div>
                        <div className="col-12 col-md-12 checkbox-container d-flex flex-column align-items-center">
                          <div className="mb-2">
                            <input
                              type="checkbox"
                              id="checkbox"
                              onChange={handleCheckboxChange}
                              checked={isCheckboxChecked}
                              onClick={handleTextInputClick}
                            />
                            <label htmlFor="checkbox" style={{ paddingLeft: "8px" }}>
                              I agree to &nbsp;
                              <a href="https://www.rifaly.com/podcasters-terms">
                                Rifaly Terms of Services
                              </a>
                              .
                            </label>
                          </div>
                          {!isCheckboxChecked && (
                            <p className="error-message">
                              Please click the checkbox to send the URL.
                            </p>
                          )}
                          {error && (
                            <p className="error-message">
                              Invalid URL. Please enter a valid URL.
                            </p>
                          )}
                        </div>
                      </div>
                    </form>
                  )}

                  {fileType.startsWith("audio/") && showAudioForm && (
                    <>
                      <aside className="p-3">
                        <ul className="file-preview">{files}</ul>
                      </aside>

                      <form onSubmit={handleSubmit(onSubmit)} className="p-3" id="audioForm">
                        <div className="row g-3 mb-3">
                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="audio" className="form-label">
                              Channel
                            </label>
                            <select
                              className="form-select"
                              {...register("channelId")}
                            >
                              <option value="" disabled selected>
                                Select Channel
                              </option>
                              {data.map((channel) => (
                                <option key={channel.id} value={channel.id}>
                                  {channel.channelName}
                                </option>
                              ))}
                            </select>
                            {errors.channelId && (
                              <p className="errors">{errors.channelId.message}</p>
                            )}
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="title" className="form-label">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationServer02"
                              placeholder="Enter Story Title"
                              required
                              {...register("title")}
                            />
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="category" className="form-label">
                              Genre
                            </label>
                            <select
                              {...register("categoryId")}
                              onChange={handleInputChange}
                              className="form-select"
                            >
                              <option disabled>Select Story Genre</option>
                              {genres.map((g) => {
                                return (
                                  <option key={g.id} value={g.id}>
                                    {g.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="language" className="form-label">
                              Publication Date and Time  
                            </label><br/>
                            <DatePicker
                              className="form-control"
                              showTimeSelect
                              selected={publishDate}
                              minDate={new Date().getTime}
                              timeIntervals={60}
                              dateFormat="dd-MMM-yyyy H:mm"
                              onChange={(date) => setPublishDate(date)}
                            />
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="category" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="0"
                              {...register("price")}
                            />
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="category" className="form-label">
                              Currency
                            </label>
                            <select
                              defaultValue={"TZS"}
                              {...register("currency")}
                              className="form-control"
                            >
                              {countries.map((c) => {
                                return (
                                  <option
                                    key={c.countryCode}
                                    value={c.currency}
                                  >
                                    {c.currency}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="language" className="form-label">
                              Language
                            </label>
                            <select
                              className="form-select"
                              {...register("language")}
                              defaultValue={"EN"}
                            >
                              <option disabled>Select Language</option>
                              <option value="EN">English</option>
                              <option value="SW">Swahili</option>
                            </select>
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="author" className="form-label">
                              Author
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={activeChannels.channelName}
                              {...register("author")}
                            />
                          </div>

                          <div className="col-12 col-md-4 mb-1">
                            <label htmlFor="audio" className="form-label">
                              Show Type
                            </label>
                            <select
                              className="form-select"
                              onChange={handleOptionChange}
                              value={selectedOption}
                            >
                              <option value="" disabled>Select Type</option>
                              <option value="season">Season</option>
                              <option value="episodes">Episodes</option>
                            </select>
                          </div>

                          {renderAdditionalForm()}
                        </div>

                        <div className="row mt-3">
                          <div className="col"></div>
                          <div className="col-auto">
                            <div className="d-flex">
                              <button
                                type="reset"
                                onClick={reset}
                                className="btn btn-outline-secondary acha-right"
                              >
                                Cancel
                              </button>
                              {loading ? (
                                <span className="btn btn-primary">
                                  <Loader
                                    type="TailSpin"
                                    color="#ffffff"
                                    height={20}
                                    width={20}
                                  />
                                </span>
                              ) : (
                                <button className="btn btn-primary" type="submit">
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}