import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {Offcanvas} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useDispatch} from 'react-redux';
import Loader from 'react-loader-spinner';
import {fetchAdminDetails, fetchAdmins} from '../../../../redux-store/Actions/admin-action';
import {updatePublisherAdminApi} from '../../../../api/user';
import {fetchPublisherApi} from '../../../../api/company';
import {userApi} from '../../../../api/api_config'


const schema = yup.object({
  // firstName: yup.string().required().min(3).max(255).label('First Name'),
}).required()


function EditPublisherAdmin(props) {

  const admin = props.data

  // const data = admin

  const {register,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });


  const [ loading, setLoading ] = useState(false);
  const [ currentRole ] = useState(admin.role);
  const [ companies, setCompanies ] = useState([])

  const [ show, setShow ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var id = parseInt(props.data.id);
  const user = JSON.parse(localStorage.getItem('RIFALYUSER'));
  useEffect(() => {
    fetchPublisherApi().then(res => setCompanies(res.data.data)).catch(e => console.log(e));
    // dispatch(fetchAdminDetails(id));
  }, []);

  const navigate = useNavigate();

  const onSubmit = data => {

    setLoading(true);
    updatePublisherAdminApi(id, data, user.token).then(res => {
      setLoading(false)
      handleClose()
      navigate("/users/publishers/");

    }).catch(error => {
      setLoading(false)
      console.log(error)
    });
  }


  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        Edit
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">

            <h6 className="header-pretitle">
              Edit
            </h6>

            <h1 className="header-title">
              Edit Admin
            </h1>

          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="fullName" className="form-label">Full Name</label>
                  <input type="text"
                    className={errors.firstName ? "form-control is-invalid" : "form-control"}
                    id="fullName"
                    placeholder="Enter full name"
                    autoFocus
                    defaultValue={admin.fullName}
                    required
                    {...register("fullName")} />

                  <p className="errors">{errors.fullName?.message}</p>

                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">Email Address</label>
                  <input type="email"
                    className={errors.email ? "form-control is-invalid" : "form-control"}
                    id="email"
                    defaultValue={admin.email}
                    placeholder="Enter Email Address"
                    required
                    {...register("email")} />


                  <p className="errors">{errors.email?.message}</p>

                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">Phone Number</label>
                  <input type="text"
                    className={errors.phone ? "form-control is-invalid" : "form-control"}
                    id="phone_number"
                    defaultValue={admin.phone}
                    placeholder="Enter Phone Number"
                    required
                    {...register("phone")} />

                  <p className="errors">{errors.phone?.message}</p>

                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">Role</label>
                  <select className="form-control" defaultValue={currentRole}
                    {...register("role")} >
                    <option>Select Role</option>
                    <option value="1">Admin</option>
                    <option value="2">Publisher</option>
                    <option value="3">Reader</option>
                  </select>

                  <p className="errors">{errors.role?.message}</p>

                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">Company</label>
                  <select className="form-control" defaultValue={admin.companyId}
                    {...register("companyId")} >
                    <option>Select Company</option>
                    {companies.map(c => {
                      return <option key={c.id} value={c.id}>{c.companyName}</option>
                    })}
                  </select>

                  <p className="errors">{errors.companyId?.message}</p>

                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status" className="form-label">Account Status</label>
                  <select className="form-control"
                    {...register("status")} defaultValue={admin.status} >
                    <option>Select Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="PENDING">Pending</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>

                  <p className="errors">{errors.status?.message}</p>

                </div>
              </div>
            </div>

            <div className="row my-4">
              <div className="col">
              </div>
              <div className="col-auto">
                {loading ? (
                  <button className="btn btn-primary">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20} />
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit" onClick={handleSubmit(onSubmit)}>Save</button>
                )}
                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default EditPublisherAdmin
