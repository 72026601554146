import React from "react";
// import { Link } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import CompanyTable from "./CompanyTable";

export default function Company() {
  const navigate = useNavigate();

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Publishers
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Publishers</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>

              <CompanyTable />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
